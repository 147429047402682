import React from "react"
import { Result, Button } from "antd"

import Layout from "../layouts"

import "./404.css"

export default () => (
  <Layout>
    <div className="page-not-found">
      <Result
        status="404"
        title="404"
        subTitle="页面不存在."
        extra={
          <Button
            type="primary"
            onClick={() => {
              typeof window !== 'undefined' && window.history.back()
            }}
          >
            返回
          </Button>
        }
      />
    </div>
  </Layout>
)
